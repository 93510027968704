// import store from '@/store'
import Swalis from '@/views/admin/swalis/index'
// import Category from '@/components/datatables/category'
// import Swalis from '@/components/datatables/Swali'
import SwaliForm from '@/views/admin/swalis/form'
import SwaliShow from '@/views/admin/swalis/show'
import SwaliEdit from '@/views/admin/swalis/edit'
// import AdminGuard from '@/helpers/guard'

export const swalis = [
  {path: "/swalis",component: Swalis, name: "Swali", beforeEnter (to, from, next) {
    let token = localStorage.getItem('access_token') || null
    let admin = localStorage.getItem('admin') || null 
    console.log("authgaurd", token, admin)
     if (token === null && admin === null){
       return next('/login')
     }
     return next()
   } },

{path: "/swalis/create",component: SwaliForm, beforeEnter (to, from, next) {
    let token = localStorage.getItem('access_token') || null
    let admin = localStorage.getItem('admin') || null 
    console.log("authgaurd", token, admin)
     if (token === null && admin === null){
       return next('/login')
     }
     return next()
   } },

{path: "/swalis/show/:code",component: SwaliShow, beforeEnter (to, from, next) {
    let token = localStorage.getItem('access_token') || null
    let admin = localStorage.getItem('admin') || null 
    console.log("authgaurd", token, admin)
     if (token === null && admin === null){
       return next('/login')
     }
     return next()
   } },
{path: "/swalis/:code/edit",component: SwaliEdit, beforeEnter (to, from, next) {
    let token = localStorage.getItem('access_token') || null
    let admin = localStorage.getItem('admin') || null 
    console.log("authgaurd", token, admin)
     if (token === null && admin === null){
       return next('/login')
     }
     return next()
   } },
]
