<template>  
<div>
    <v-container class="fill-height">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-card class="elevation-12">
                  <ValidationObserver
                    ref="observer"
                    v-slot="{}"
                  >
               <v-row class="fill-height">
                 
                 <v-col cols="12" md="12">
                    <v-card-text >
                      <h1 class="teal--text text--accent-3">
                        {{title}}
                      </h1>
                      
                      <template >  
                                  <v-snackbar
                                  :timeout="timeout"
                                  v-model="snackbar"
                                  :value="true"
                                  right
                                  shaped
                                  bottom
                                  dismisable
                                  color="red"
                                >
                              <h2>{{errs.error}}</h2>
                              <p>{{errs.message}}</p>
                                </v-snackbar> 
                            
                          </template>
                        <v-form>
                                 <v-container>
                                  <v-row>
                                   

                                    <v-col
                                      cols="12"
                                      md="6"
                                    ><validation-provider
                                        v-slot="{ errors }"
                                        name="Title"
                                        rules="required|"
                                      >
                                      <v-text-field
                                        v-model="form.title"
                                        label="Title"
                                        :error-messages="errors"   
                                        required
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col> 
                                     <v-col
                                      cols="12"
                                      md="6"
                                    > <validation-provider
                                        v-slot="{ errors }"
                                        name="university"
                                        rules="required"
                                      >
                                      <v-text-field
                                        v-model="form.university"
                                        :error-messages="errors"      
                                        label="University"
                                        required
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="6"
                                    > <validation-provider
                                        v-slot="{ errors }"
                                        name="subject"
                                        rules="required"
                                      >
                                      <v-text-field
                                        v-model="form.subject"
                                        :error-messages="errors"      
                                        label="Subject"
                                        required
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col>

                                    <v-col
                                      cols="12"
                                      md="6"
                                    ><validation-provider
                                        v-slot="{ errors }"
                                        name="topic"
                                        rules="required|"
                                      >
                                      <v-text-field
                                        v-model="form.topic"
                                        label="Topic"
                                        :error-messages="errors"   
                                        required
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col> 
                                  </v-row>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      md="4"
                                    > <validation-provider
                                        v-slot="{ errors }"
                                        name="pages"
                                        rules="required"
                                      >
                                      <v-text-field
                                        type="number"
                                        v-model="form.pages"
                                        :error-messages="errors"      
                                        label="Pages"
                                        required
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col>

                                     <v-col
                                      cols="12"
                                      md="4"
                                    > <validation-provider
                                        v-slot="{ errors }"
                                        name="views"
                                        rules="required"
                                      >
                                      <v-text-field
                                        type="number"
                                        v-model="form.views"
                                        :error-messages="errors"      
                                        label="Views"
                                        required
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col> <v-col
                                      cols="12"
                                      md="4"
                                    > <validation-provider
                                        v-slot="{ errors }"
                                        name="likes"
                                        rules="required"
                                      >
                                      <v-text-field
                                        type="number"
                                        v-model="form.likes"
                                        :error-messages="errors"      
                                        label="Likes"
                                        required
                                      ></v-text-field>
                                      </validation-provider>
                                    </v-col>
                                  </v-row>
                                   <v-row>
                                    <v-col
                                      cols="12"
                                      md="12"
                                    > 
                                    <h2> Caption</h2>
                                    <vue-editor v-model="form.caption"></vue-editor>
                                    </v-col>
                                   </v-row>
                                     <v-row>
                                    <v-col
                                      cols="12"
                                      md="12"
                                    > 
                                    <h2>Content</h2>
                                    <vue-editor v-model="form.content"></vue-editor>
                                    </v-col>
                                   </v-row>
                                   <v-row>
                                    <v-col
                                      cols="12"
                                      md="8"
                                    >   <v-file-input  
                                         accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        label="Change file"
                                        required
                                        type="file"
                                        @change="onFile"
                                      ></v-file-input>
                                      </v-col>

                                    <v-col
                                      cols="12"
                                      md="4"
                                    > 
                                    {{form.file.split("/")[3]}}
                                    </v-col>
                                   </v-row>
                                  
                                </v-container>
                        
                      </v-form>
                      <!-- <h3 class="text-center mt-3">have an account with us </h3> -->
                    </v-card-text>
                    <div class="text-center mt-3">
                      <v-btn class="ma-3" dark @click="Back()">Back</v-btn>
                      <v-btn class="ma-3" color="teal accent-3" @click="save()">Save</v-btn>
                    </div>
                    </v-col>
               </v-row>
                  </ValidationObserver>
              
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- <item /> -->
    <itemmodal :dialogitemi="dialogitemi" :itemiclose="itemiclose" :savor="savor"/>
    <!--<tax :dialogtax="dialogtax" :taxclose="taxclose" :taxs="taxs"/> -->
  <categorymodal :dialogcategory="dialogcategory" :categoryclose="categoryclose" :majorcode="majorc"/>
  <majorcatmodal :dialogmajorcat="dialogmajorcat" :majorcatclose="majorcatclose"/>

</div>
</template>

<script>
 import axios from '../../../axios'
import cons from '@/helpers/myconstants'
import categorymodal from '@/components/modals/category'
import majorcatmodal from '@/components/modals/majorcat'
import itemmodal from '@/components/modals/item'
import { VueEditor } from "vue2-editor"
  import { required, email, max } from 'vee-validate/dist/rules'
  import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
// import Item from '../../../components/modals/item.vue'
 setInteractionMode('eager')
  extend('required', {
    ...required,
    message: '{_field_} can not be empty',
  })
  extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
  })
  extend('email', {
    ...email,
    message: 'Email must be valid',
  })
export default {
  name: "login",
  components: {
      ValidationProvider,
      ValidationObserver,
      categorymodal,
      majorcatmodal,
      VueEditor,
      itemmodal,
    },
  data(){
    return{
        form:{
            title:'',
            caption:'',
            content:'',
            university:'',
            subject: '',
            topic: '',
            views:0,
            likes: 0,
            pages: 0,
            price:'',
            picture:null,
        },
        host:'',
        files: [],
        filesa: [],
        errs:{},
        option:[],
        suppliers: [],
        title:'Edit Swali',
        init:'api/swalis/view',
        redirect: '/swalis',
        store: 'api/swalis',
        categs: 'api/categorys',
        snackbar:false,
        timeout:3000,
    }
  },

  created(){
      this.fetchData()
      this.host = cons.host
  },
  methods:{
    onFile(e){
      console.log(e)
      this.form.picture = e
    },
    Back(){
      this.$router.push(this.redirect)
    },
    View(code){
      this.$router.push(`${this.redirect}/show/${code }`)
    },
    savor(payload){
      this.form.services.push(payload)
      console.log("services", this.form.services)
    },
    remove(index){
      this.form.services.splice(index,1)
    },
   async save(){
          try{
            if ( this.form.picture !== null || this.form.picture !== undefined){
                this.$store.commit("setLoaderTrue")
                  let fd = new FormData();
                  fd.append("picture", this.form.picture)
                  fd.append("title", this.form.title)
                  fd.append("caption", this.form.caption)
                  fd.append("content", this.form.content)
                  fd.append("url", this.form.title.split(' ').join('-'))
                  fd.append("likes", this.form.likes)
                  fd.append("pages", this.form.pages)
                  fd.append("views", this.form.views)
                  fd.append("university", this.form.university)
                  fd.append("subject", this.form.subject)
                  fd.append("topic", this.form.topic)
                  // fd.append("colors", JSON.stringify(this.form.colors))
              // axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
            const {data} = await axios.put(`${this.store}/${this.$route.params.code}`,  
                fd ,{'Content-Type': 'multipart/form-data'})
                  if(data){
                  this.$router.push(this.redirect)
                  }
                  this.$store.commit("setLoader")
            } else{
                this.$store.commit("setLoaderTrue")
                console.log(this.form.newarrivals)
                  let fd = new FormData();
                  fd.append("title", this.form.title)
                  fd.append("caption", this.form.caption)
                  fd.append("content", this.form.content)
                  fd.append("url", this.form.title.split(' ').join('-'))
                  fd.append("likes", this.form.likes)
                  fd.append("pages", this.form.pages)
                  fd.append("views", this.form.views)
                  fd.append("university", this.form.university)
                  fd.append("subject", this.form.subject)
                  fd.append("topic", this.form.topic)
                  // fd.append("colors", JSON.stringify(this.form.colors))
              // axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
            const {data} = await axios.put(`${this.store}/${this.$route.params.code}`,  
                fd ,{'Content-Type': 'multipart/form-data'})
                  if(data){
                  this.$router.push(this.redirect)
                  }
                  this.$store.commit("setLoader")
            }
        }catch(err){
            this.snackbar = true
            this.errs = err
                this.$store.commit("setLoader")
        }
  },
  async fetchData(){
        try{
              this.$store.commit("setLoaderTrue")
            const {data} = await axios.get(`${this.store}/${this.$route.params.code}`)
            
            // const {user} = data
            if(data){
            this.form = data
            }
                this.$store.commit("setLoader")
          }catch(err){
          this.snackbar = true
          //   console.log(err)
          this.errs = err.response.data
                this.$store.commit("setLoader")
          } 
      }, 
  }
  }
</script>

<style>
</style>