var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"value":true,"right":"","shaped":"","bottom":"","dismisable":"","color":"red"},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('h2',[_vm._v(_vm._s(_vm.errs.error))]),_c('p',[_vm._v(_vm._s(_vm.errs.message))])]),_c('v-card',{staticClass:"ma-3 pa-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-card-title',[_vm._v(" Upload A file")])],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-file-input',{attrs:{"accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","label":"Upload a file","required":"","type":"file"},on:{"change":_vm.onFile}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.writers,"chips":"","clearable":"","hide-details":"","hide-selected":"","item-text":"f_name","item-value":"u_name","label":"Search writer...","solo":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" Search for "),_c('strong',[_vm._v("Writer")])])],1)]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"blue-grey"}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-check-box-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.u_name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-avatar',{staticClass:"headline font-weight-light white--text",attrs:{"color":"indigo"}},[_vm._v(" "+_vm._s(item.f_name.charAt(0))+" ")]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.f_name)}}),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.title))])],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-check-box-outline")])],1)]}}]),model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.UploadFile()}}},[_vm._v(" Upload ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card-title',[_vm._v(" Search and Replace")])],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"Old"},model:{value:(_vm.form.old),callback:function ($$v) {_vm.$set(_vm.form, "old", $$v)},expression:"form.old"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"label":"New"},model:{value:(_vm.form.new),callback:function ($$v) {_vm.$set(_vm.form, "new", $$v)},expression:"form.new"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"depressed":"","color":"primary"},on:{"click":_vm.Replace}},[_vm._v(" Replace ")])],1)],1)],1),_c('DataViewer',{attrs:{"source":_vm.source,"headers":_vm.headers,"create":_vm.create,"title":_vm.title,"show":_vm.show}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }