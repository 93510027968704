<template>
  <v-container>
    
<DataViewer :source="source" :headers="headers" :create="create" :title="title" :show="show" />
  </v-container>

</template>
<script>
import DataViewer from '@/components/viewers/dataviewerphone.vue'
export default {
      name: 'phonelist',
    components:{ 
        DataViewer,
    },
      data(){
          return{
              title:'Contact list',
              source: '/api/phones',
              create: '/phones/create',
              show:'/phonelist',
              headers:[
                { text: 'Phone', value: 'Phone' },
                { text: 'Question', value: 'Question' },
                // { text: 'Actions', value: 'actions', sortable: false }
              ]
          }
      },
}
</script>

<style>

</style>