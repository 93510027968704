// import store from '@/store'
import phonelist from '@/views/admin/phonelist/index'
import phonelistShow from '@/views/admin/phonelist/show'

export const phones = [
  {path: "/phonelist",component: phonelist, name: "phonelist", beforeEnter (to, from, next) {
    let token = localStorage.getItem('access_token') || null
    let admin = localStorage.getItem('admin') || null 
    console.log("authgaurd", token, admin)
     if (token === null && admin === null){
       return next('/login')
     }
     return next()
   } },

{path: "/phonelist/show/:code",component: phonelistShow, beforeEnter (to, from, next) {
    let token = localStorage.getItem('access_token') || null
    let admin = localStorage.getItem('admin') || null 
    console.log("authgaurd", token, admin)
     if (token === null && admin === null){
       return next('/login')
     }
     return next()
   } },
// }},

]
